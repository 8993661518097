import Page404Template from 'shared-components/templates/PageNotFoundTemplate'
import PageContainer from 'shared-components/service/PageContainer'
import { getPage404Props } from 'props-getters/404'
import { publicConfig } from 'config'
import { ExtractGetterProps } from 'shared-definitions/util-types'
import Layout from 'components/Layout'
import { getds } from 'ds'
import { useCallback, useEffect, useState } from 'react'
import { DSButtonsList } from 'shared-definitions/types'

const Page404: React.VFC<ExtractGetterProps<typeof getPage404Props>> = ({
  page,
  common,
  ts,
  preview,
}) => {
  const pageLoader = useCallback(
    async (p: number) => {
      const dataSource = await getds({ ts })
      const { data } = await dataSource.getPage404(
        { page: p, perPage: page.hotPosts.perPage },
        { page: p, perPage: page.posts.perPage }
      )
      return [
        {
          left: data.hotPosts.items,
          right: data.posts.items,
        },
      ]
    },
    [ts, page]
  )

  const [suggestions, setSuggestions] = useState<DSButtonsList | null | undefined>(undefined)

  // here we fetch suggestions for 404 page
  useEffect(() => {
    void (async () => {
      const dataSource = await getds()

      const query = location.pathname.split('/').filter(i => i !== '')

      if (query.length === 0) {
        setSuggestions(null)
        return
      }

      // we fetch 3 posts that have the same first path segment as the current path
      const { data } = await dataSource.getSearchPage(query[0], 1, 3)
      if (!data.posts || data.posts.items.length === 0) {
        setSuggestions(null)
        return
      }

      setSuggestions({
        title: 'Maybe you are looking for:',
        buttons:
          data.posts?.items.map(i => ({
            label: i.title,
            pLink: i.pLink,
          })) || [],
      })
    })()
  }, [])

  // if page is not found, we reload the page
  // because of nextjs bug with undefined page
  // wia nextjs navigation
  useEffect(() => {
    if (!page) {
      window.location.reload()
    }
  }, [])

  if (!page) {
    return null
  }

  return (
    <PageContainer layout={Layout} props={{ page, common, ts, preview, rss: false }}>
      <Page404Template page={page} pageLoader={pageLoader} suggestions={suggestions} />
    </PageContainer>
  )
}

export const getStaticProps = getPage404Props(publicConfig('postsPerPage'))

export default Page404
